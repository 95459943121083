import * as yup from 'yup';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const contactUsSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().required().matches(phoneRegExp),
  company: yup.string().required(),
  detail: yup.string().required(),
});

export const defaultFormValues = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  company: '',
  detail: '',
};
